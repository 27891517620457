





































































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { ProductMapService } from '@/services/product-map-service';

@Component({
  components: {

  }
})
export default class wechatImageText extends Vue {
  public form: any = {
    pages: 1,
    size: 10,
    current: 1,
    total: 0,
    typeId: '',
  };
  private tableData: any[] = [];
  public applications: any[] = [];
  public CreateTime: any[] = [];
  public kingList: any[] = [];//根据专栏获取分类
  public loading: Boolean = true;
  public kindshow: Boolean = false;
  private currentRow: any = null;
  private linkPre: string = '';

  @Inject(ProductMapService) private ProductMapService!: ProductMapService;

  public async created(): Promise<void> {
    //  await this.applicationsList();
    await this.getList();
  }

  //预览图文
  public preview(row: any) {
    this.linkPre = `${location.origin}/wecom-mfa-front/#/productMap?productMapId=` + row.id  + "&type=1&lookCont="
    //this.linkPre = "http://localhost:8082/historynew-wecom-front/?articleId=" + row.id + "&PC=1";
  }


  public search() {
    this.form.current = 1;
    if (this.CreateTime && this.CreateTime.length > 0) {
      this.form.startTime = this.CreateTime[0];
      this.form.endTime = this.CreateTime[1];
    } else {
      this.form.startTime = "";
      this.form.endTime = "";
    }
    this.getList()
  }
  //分页相关
  public handleSizeChange(size: number) {
    this.form.size = size;
    this.getList();
  }
  public handleCurrentChange(current: number) {
    this.form.current = current;
    this.getList();
  }
  //获取数据源
  public async getList() {
    this.loading = true;
    const res = await this.ProductMapService.applicationsList(this.form);
    this.loading = false;
    let data: any = res.records
    this.formData(data)
    this.form.total = res.total;
    this.form.pages = res.pages;
    this.form.size = res.size;
    this.form.current = res.current;
  }
  //处理专栏id对应的name
  public async formData(res: any) {
    let data = res
    for (var item = 0; item < data.length; item++) {
      for (var i = 0; i < this.applications.length; i++) {
        if (this.applications[i].channelAgentId == data[item].agentId) {
          data[item].agentName = this.applications[i].name
        }
      }
    }
    this.tableData = data
  }
  //获取专栏
  // public async applicationsList(): Promise<void> {
  //   const res = await this.ProductMapService.applicationsList();
  //   this.applications = res;
  //   for (var i = 0; i < this.applications.length; i++) {
  //     this.applications[i].channelAgentId = Number(this.applications[i].channelAgentId);
  //   }
  // }
  // channelChange 选择专栏监听
  public async channelChange(val: any): Promise<void> {
    console.log('186', val)
    this.kingList = [];
    this.form.typeId = '';
    if (val != '') {
      this.kindshow = true;
      this.getKindlist(val)
    } else {
      this.kindshow = false;
    }


  }
  //获取图文分类
  public async getKindlist(val: any) {
    var data_ = {
      agentId: val,
      size: 0,
      current: 0
    }
    const res = await this.ProductMapService.articleTypeQuery(data_);
    let data: any = res.records
    this.kingList = data;
    // this.form.typeId = data[0].id
  }
  //删除图文
  private async handDelete(row: any): Promise<void> {
    let param = {
      ids: row.id
    }
    try {
      await this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      await this.ProductMapService.articleDelete(row.id);
      this.getList()
      this.$message({
        message: '删除成功',
        type: 'success'
      });
    } catch (e) {
      this.$message({
        type: 'info',
        message: '已取消删除'
      });
    }
  }
  //修改
  public handUpdate(row: any) {
    localStorage.setItem('productMapType', JSON.stringify(row))
    this.$router.push("/addProductMap");
  }
  //图文分类
  public imageTextType() {
    this.$router.push("/imageTextClassify");
  }
  //创建图文
  public createImageText() {
    localStorage.setItem('productMapType', '1')
    this.$router.push("/addProductMap");
  }
  // 重置
  public reset() {
    var that = this;
    that.form = {
      typeId: '',
    };
    // that.form = {
    //   pages: 1,
    //   size: 10,
    //   current: 1,
    //   total: 0,
    //   typeId: '',
    // };
    that.CreateTime = [];
    this.getList()
    // that.search()
  }
  private mounted() {
    document.onkeydown = (e) => {
      let key = (window.event as any).keyCode;
      if (key == 13) {
        this.search()
      }
    }
  }
}
