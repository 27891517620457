






































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import { I18nService } from '@cds/i18n';
import moment from 'moment';
//引入组件
import VueUeditorWrap from 'vue-ueditor-wrap'; //es6
import { ProductMapService } from '@/services/product-map-service';
import { WechatSendMessageGroupService } from '@/services/wechat-send-messages-group-service';
import mapChooseGroup from '@/views/productMap/mapChooseGroup.vue';
// import wechatImageText from '@/components/wechatImageText/index.vue';
import productMapOpenDialog from '@/components/wechatEditor/productMapOpenDialog.vue';


@Component({
  components: {
    VueUeditorWrap,
    // wechatImageText,
    productMapOpenDialog,
    mapChooseGroup
  }
})
export default class addProductMap extends Vue {
  public myConfig = {
    // 编辑器不自动被内容撑高
    autoHeightEnabled: false,
    // 初始容器高度
    initialFrameHeight: 500,
    // 初始容器宽度
    initialFrameWidth: '100%',
    // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
    serverUrl: '/api/application/ueditor/config',
    // serverUrl: '/api/application/img/upload/imgUpload',
    // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
    UEDITOR_HOME_URL: '/ueditor/',
    toolbars: [[
      'fullscreen', 'source', '|', 'undo', 'redo', 'bold', 'italic', 'underline', 'fontborder', 'strikethrough', 'superscript', 'subscript',
      'removeformat', 'formatmatch', 'autotypeset', 'blockquote', 'pasteplain', 'forecolor',
      'backcolor', 'insertorderedlist', 'insertunorderedlist', 'selectall', 'cleardoc',
      'fontfamily', 'fontsize', 'simpleupload', 'insertimage', 'emotion', /*'scrawl',*/
      /*'music',*/ 'insertvideo', 'insertaudio', 'attachment', 'map',
      'rowspacingtop', 'rowspacingbottom', 'lineheight', 'link', 'unlink'
    ]],
    'fontfamily': [
      { label: '', name: 'songti', val: '宋体,SimSun' },
      { label: '仿宋', name: 'fangsong', val: '仿宋,FangSong' },
      { label: '仿宋_GB2312', name: 'fangsong', val: '仿宋_GB2312,FangSong' },
      { label: '', name: 'kaiti', val: '楷体,楷体_GB2312, SimKai' },
      { label: '', name: 'yahei', val: '微软雅黑,Microsoft YaHei' },
      { label: '', name: 'heiti', val: '黑体, SimHei' },
      { label: '', name: 'lishu', val: '隶书, SimLi' },
      { label: '', name: 'andaleMono', val: 'andale mono' },
      { label: '', name: 'arial', val: 'arial, helvetica,sans-serif' },
      { label: '', name: 'arialBlack', val: 'arial black,avant garde' },
      { label: '', name: 'comicSansMs', val: 'comic sans ms' },
      { label: '', name: 'impact', val: 'impact,chicago' },
      { label: '', name: 'timesNewRoman', val: 'times new roman' }
    ]
  };
  public ishowImage: Boolean = false;//默认是没有选中数据tableData==0
  public title: String = '选择可见范围';
  public imgUrl2: String = '';
  public forId: String = '';
  public labelPosition: String = 'top';
  public chooseId: String = '';
  public form: any = {
    name: '',
    sentSource: '',
    sentSourceName: '',
    title: '',
    openScope: '',
    coverImage: '',
    graphicAddress: '',
    lookContent: ''

  };
  public backgroud1: number = 0; // 0否，1是
  public spicurlAlt: String = '';
  public createTime: any = '';
  public disabledTosend: Boolean = true;//是否可以转发 默认不可编辑
  public disabledToall: Boolean = false; //可见范围 默认可编辑
  public queryParams: any = { //查询参数
    agentId: '',
    total: 0
  };
  public querySend: any = {
    channelId: ''
  };
  public rules: any = {
    agentId: [
      { required: true, message: '请选择专栏', trigger: ['blur', 'change'] }],
    title: [
      { required: true, message: '请输入标题', trigger: 'blur' }
    ],
    name: [
      { required: true, message: '请输入素材名称', trigger: 'blur' }
    ],
    groupListShow: [
      { required: true, message: '请输入可见范围', trigger: 'blur' }
    ],
    picurl: [
      { required: true, message: '请上传封面图片', trigger: ['blur', 'change'] }
    ],
    sentSource: [
      { required: true, message: '请输入发送源头', trigger: 'blur' }
    ],
    sentSourceName: [
      { required: true, message: '请输入发送源头', trigger: 'blur' }
    ],
    coverImage: [
      { required: true, message: '请上传封面图', trigger: 'blur' }
    ],
    lookContent: [
      { required: true, message: '请输入内容', trigger: 'blur' }
    ]
  };
  public activeName: String = 'first';
  public seen: Boolean = false;
  public chooseFlag: any = '';//鼠标移上去的索引
  public type: any = ''; //判断是修改还是添加
  public id: any = '';
  public imageType: String = ''; //判断是封面还是图文列表/历史消息 封面
  public dialogFormVisible: boolean = false;//是否显示弹框
  public readonly: boolean = true;
  public titles: String = '选择图文素材';
  public agentDisable: Boolean = false;//专栏、应用是否可选
  public loading: boolean = false;
  public articleIds: any[] = []; //存储之前的id
  public articleType: any[] = []; //存储类型
  private applications: any[] = []; //存储专栏
  private imageList: any[] = []; //存储图文集合
  private sendMessageList: any[] = []; //存储群发消息分组
  private groupList: any[] = [];
  @Inject(ProductMapService) private ProductMapService!: ProductMapService;
  @Inject(WechatSendMessageGroupService) private wechatSendMessageGroupService!: WechatSendMessageGroupService;

  @Watch('form.lookContent', { deep: true })
  handleWatch() {
    //  this.form.content = this.$xss(this.form.content);
  }

  public async created(): Promise<void> {
    const currentTime = new Date().getTime();
    this.createTime = moment(currentTime).format('YYYY-MM-DD HH:mm');
    this.type = JSON.parse(localStorage.getItem('productMapType') || '');
    localStorage.removeItem('res');
    console.log(this.type);
    //查询专栏
    // await this.applicationsList();
    await this.getChannel();
    //this.type==1是创建
    console.log('this.type', this.type);
    if (this.type != '1') { //代表是修改
      this.id = this.type.id; //拿到主图文id
      await this.articleGet();
      this.agentDisable = true;
      // this.form.historyUrl = `${location.origin}/wecom-mfa-front/productMap?productMapId=` + this.form.id;
      // if(this.type.publish == 1){
      //   this.agentDisable = true;
      // }
    } else { //添加的话默认push数据
      this.articleIds.push('0');
      this.imageList.push(this.form);
    }
    this.form.id = this.$route.query.detailId;
  }

  //选择可见范围
  public choose() {
    this.dialogFormVisible = true;
  }

  //获取专栏
  public async applicationsList(): Promise<void> {
    const res = await this.ProductMapService.applicationsList(this.form);
    this.applications = res;
    if (this.type == '1' && this.applications.length > 0) {//如果是添加的话，默认选中一个专栏
      // this.form.agentId = this.applications[0].channelAgentId
      this.$set(this.form, 'agentId', this.applications[0].channelAgentId);
      this.agentChanged(this.form.agentId);
    }
  }

  //获取源头
  public async getChannel(): Promise<void> {
    const res = await this.ProductMapService.getChannelList({});
    console.log(res, '获取源头');
    this.applications = res;
    // if (this.type == '1' && this.applications.length > 0) {//如果是添加的话，默认选中一个专栏
    //   // this.form.agentId = this.applications[0].channelAgentId
    //   this.$set(this.form, 'agentId', this.applications[0].channelAgentId);
    //   this.agentChanged(this.form.agentId);
    // }
  }

  //获取指定分组
  public async sendMessageListAll(): Promise<void> {
    const res = await this.wechatSendMessageGroupService.sendMessageListAll(this.querySend);
    this.sendMessageList = res;
  }

  // //获取指定分组
  // public async getSelectGroupList(data: Dict<any>): Promise<void> {
  //   const res = await this.ProductMapService.getSelectGroupList(data);
  //   console.log(res, 'res');
  //   // this.sendMessageList = res;
  // }

  //调起图片弹框
  public addPicture(types: any) {
    (this.$refs.childDialog as productMapOpenDialog).upImage();
    this.imageType = types;
    console.log(types, 'types');
  }

  //得到子组件传过来的值
  public getChild(val: any): void {
    this.$nextTick(() => {
      if (this.imageType == '1') {
        this.form.coverImage = val.src;
        this.imgUrl2 = val.src;
      }
      if (this.imageType == '2') {
        this.form.coverImage = val.src;
        this.spicurlAlt = val.alt;
        this.imgUrl2 = val.src;

      }
    });
  }

  //查询指定图文
  public async articleGet(): Promise<void> {
    const res = await this.ProductMapService.articleGet({ id: this.id });
    console.log(res, '请求结果');
    this.form = res;
    this.chooseId = res.sentSource;
    this.form.historyUrl = `${location.origin}/wecom-mfa-front/#/productMap?productMapId=` + this.form.id + '&type=1&lookCont=';
    this.imgUrl2 = res.coverImage
    this.form.groupListShow = res.nvsWcUserGroups.map((item: any) => {
      return item.nvsWcUserGroupName;
    });
    this.form.groupList = res.nvsWcUserGroups;
  }

  //回显数据特殊处理
  public async formData() {
    for (let i = 0; i < this.imageList.length; i++) {
      let arr: any = this.imageList[i];
      //修改才pushid，选择图文不push
      if (this.type != '1') {
        this.articleIds.push(this.imageList[i].id);
      }
      this.agentChanged(arr.agentId);
      if (arr.groupIds != undefined && arr.groupIds != null && arr.groupIds != '') {
        this.form.groupIds = arr.groupIds = arr.groupIds.split(',').map(Number);
      }
      this.imageList[i].typeId = arr.typeId == 0 ? '' : arr.typeId;
      this.imageList[i].moments = arr.moments == null ? 0 : arr.moments;//是否分享到朋友圈
      //截取名字
      if (arr.spicurl != null) {
        let path: any = arr.spicurl;
        if (path.indexOf('/') > 0)//如果包含有"/"号 从最后一个"/"号+1的位置开始截取字符串
        {
          this.spicurlAlt = path.substring(path.lastIndexOf('/') + 1, path.length);
        } else {
          this.spicurlAlt = path;
        }
      }
    }
  }

  public changeSendType(val: any): void {
    if (val == 0) {
      this.disabledTosend = true;
      this.disabledToall = false;
      this.form.tosend = 0;
      this.form.moments = 0;
    }
    if (val == 1) {
      this.disabledTosend = false;
      this.disabledToall = true;
      this.form.toall = 2;
    }
    if (val == 2) {
      this.disabledTosend = false;
      this.disabledToall = true;
      this.form.toall = 1;
    }
  }

  //预览
  public preview(res: any) {
    console.log(res, 'previewres');
    localStorage.setItem('lookCont', res.lookContent);
    let link = `${location.origin}/wecom-mfa-front/#/productMap?productMapId=` + res.id + '&type=2&lookCont=' + res.lookContent;
    window.open(link);
  }

  //获取图文类型
  public async getTypeQuery(): Promise<void> {
    const res = await this.ProductMapService.articleTypeQuery(this.queryParams);
    this.articleType = res.records;
  }

  public async agentChanged(val: any) {
    console.log(val, 'agentChanged');
    this.chooseId = val;
    // let data = {
    //   "current":1,
    //   "size":10,
    //   "agentId":val,
    //   "groupName":'',
    // }
    //  await this.getSelectGroupList(data)
    //  let checkId = this.applications.find(item => item.channelAgentId == val).id;
    //   this.querySend.channelId = checkId;  //根据id查询用户分组
    //   this.form.channelId = checkId;
    //  await this.getTypeQuery();
    // // 查询指定分组
    //  await this.sendMessageListAll();
  }

  //保存前把数组转换为字符串
  public stringData() {
    for (let i = 0; i < this.imageList.length; i++) {
      let arr: any = this.imageList[i];
      this.imageList[i].content = arr.content;
      if (arr.toall == '1') {
        this.imageList[i].groupIds = '';
      }
      if (arr.groupIds != undefined && arr.groupIds != null && arr.groupIds.length > 0 && arr.groupIds != '') {
        this.imageList[i].groupIds = arr.groupIds.join(',');
      } else {
        this.imageList[i].groupIds = '';
      }
    }
  }

  //保存
  public submitForm(formName: any): void {
    // const currentTime = new Date().getTime();
    // if (formName == '1') { //提交
    //
    //   this.form.lookContent = '';
    //   this.form.backgroud = this.backgroud1;
    // } else {
    //   this.form.lookTime = moment(currentTime).format('YYYY-MM-DD HH:mm:ss');
    //   this.form.lookContent = this.form.content;
    // }
    //
    // this.form.startTime = this.form.times[0];
    // this.form.endTime = this.form.times[1];
    const addRef = (this.$refs.form as any);
    this.applications.map((item: any) => {
      if (item.channelAgentId == this.form.sentSource) {
        this.form.sentSourceName = item.channelName;
        return;
      }
    });

    let ids: any = [];
    if (this.form.groupList) {

      if(this.form.groupList.length ==1){
        this.form.openScope = this.form.groupList[0].id || this.form.groupList[0].nvsWcUserGroupId
      }
      else{
        this.form.groupList.map((item: any) => {
          if(item.id){
            ids.push(item.id);
          }
          else if(item.nvsWcUserGroupId){
            ids.push(item.nvsWcUserGroupId);
          }
        });
        this.form.openScope = ids.join(',');
      }
    } else {
      console.log("2222222")
      if (this.form.nvsWcUserGroups) {
        // if(this.form.nvsWcUserGroups.length ==1){
        //   this.form.openScope = this.form.nvsWcUserGroups[0].nvsWcUserGroupId
        // }
        // else{
          this.form.nvsWcUserGroups.map((item: any) => {
            ids.push(item.nvsWcUserGroupId);
          });
          this.form.openScope = ids.join(',');
       // }
      }
    }
    addRef.validate(async (valid: any) => {
      if (valid) {
        let res: any = {};
        //console.log(this.type, 'this.type');
        if (this.type != '1') { //代表是修改
          console.log('修改');
          this.form.id = this.type.id;
          // res = await this.ProductMapService.articleUpdate(this.form);
          if (formName == '提交') {
            console.log('提交');
            // let forId = localStorage.getItem('res');
            // console.log(forId, 'forId');
            // if(forId){
            this.form.graphicAddress = null
            this.form.content = this.form.lookContent
            res = await this.ProductMapService.articleUpdate(this.form);
            this.$message({
              message: '修改成功',
              type: 'success'
            });
            this.$router.push('/productMap');

          } else if (formName == '预览') {
            console.log('预览');
            this.form.graphicAddress = null
            console.log(this.form.id,'this.form.id')
            let paramData = {
              "id":this.form.id,
              'lookContent':this.form.lookContent
            }

            res = await this.ProductMapService.articlePreview(paramData,"2");

            //res = await this.ProductMapService.articleUpdate(this.form);
            localStorage.setItem('res', JSON.stringify(res));
            console.log(this.form, ',this.form');

            if (this.form.id) {
              this.preview(this.form);
            } else {
              this.preview(res);
            }
          }
        } else if (this.type == '1') {
          console.log('新增');
          if (formName == '预览') {
            console.log('预览');
            let forId = localStorage.getItem('res');
            if (this.forId) {
              console.log(this.form,'this.form')
              this.form.graphicAddress = null
              let paramData = {
                "id":this.forId,
                'lookContent':this.form.lookContent
              }
              let res2: any = {};
              res2 = await this.ProductMapService.articlePreview(paramData,"2");
              //res = await this.ProductMapService.articleUpdate(this.form);
              this.form.id = this.forId;
              if (this.form.id) {
                this.preview(this.form);
              } else {
                this.preview(res);
              }
              ;
            } else {
              this.form.graphicAddress = `${location.origin}/wecom-mfa-front/#/productMap`
              res = await this.ProductMapService.articleAdd(this.form);
              localStorage.setItem('res', JSON.stringify(res));
              this.form.id = res.id;
              this.forId = res.id;
              let paramData = {
                "id":this.form.id,
                'lookContent':this.form.lookContent
              }
              console.log(paramData,'articleAddparamData')
              let res2: any = {};
              res2 = await this.ProductMapService.articlePreview(paramData,"2");
              if (this.form.id) {
                this.preview(this.form);
              } else {
                this.preview(res);
              }
              ;
            }
          } else if (formName == '提交') {
            console.log('提交');
            let forId = localStorage.getItem('res');
            console.log(forId, 'forId');
            if (forId) {
              this.form.graphicAddress = null
              this.form.content = this.form.lookContent
              res = await this.ProductMapService.articleUpdate(this.form);
              this.$router.push('/productMap');
            } else {
              this.form.graphicAddress = `${location.origin}/wecom-mfa-front/#/productMap`
              this.form.content = this.form.lookContent
              res = await this.ProductMapService.articleAdd(this.form);
              console.log(res,'提交提交结果')
              let paramData = {
                "id":res.id,
                'lookContent':this.form.lookContent
              }
              console.log(paramData,'articleAddparamData')
              res = await this.ProductMapService.articlePreview(paramData,"2");
              this.$message({
                message: '添加成功',
                type: 'success'
              });
              this.$router.push('/productMap');
            }
          }
        }
      } else {
        this.$message({
          type: 'info',
          message: '请输入对应字段'
        });
        return false;
      }
    });
    console.log(this.form, 'submitForm.form');
  }

  //返回
  public async handleGoBack(): Promise<void> {
    this.$router.go(-1);
  }

  public async handleClick(tab: any, event: any): Promise<void> {
    console.log(tab, event);
  }

  //复制
  public copyUrl() {
    var oInput = document.createElement('input');
    oInput.value = this.form.historyUrl;
    document.body.appendChild(oInput);
    oInput.select();
    document.execCommand('Copy');
    this.$message({
      type: 'success',
      message: '复制成功'
    });
    oInput.remove();
  }

  //得到子组件的值
  public selectChange(e: any): void {
    console.log(e, 'eeeeee');
    if (e == false) {
      this.dialogFormVisible = false;
      this.$message({
        type: 'info',
        message: '至少选择一个'
      });

      return;
    }
    this.form.groupList = [];
    e.forEach((item: Dict<any>) => {
      let json: any = {};
      json.groupName = item.groupName;
      json.id = item.id;
      this.form.groupList.push(json);
    });
    this.formatData(this.form.groupList);
    console.log(this.form.groupList, 'this.form.groupList');
    this.dialogFormVisible = false;
  }

  //应用分组数据回显处理
  public async formatData(data: any) {
    console.log(data, 'data11111');
    //应用分组数组回显处理
    let arr: any = [];
    for (var item = 0; item < data.length; item++) {
      arr.push(data[item].groupName);
    }
    console.log(arr, 'formatDataArr');
    this.$nextTick(function() {
      let list = arr.join(',');
      this.$set(this.form, 'groupListShow', list);
      console.log(list, 'formatDatalist');
    });
  }

  /**
   * -END- CALL SERVICE
   */
}
