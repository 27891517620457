import { Inject } from '@cds/common';
import { HttpService } from '@/services/common/http.service';
import { Notification } from 'element-ui';

export class ProductMapService {
  @Inject(HttpService) private http!: HttpService;

  //获取列表
  public applicationsList(param: Dict<any>): Promise<any> {
    return this.http.post('/api/application/productmap/list',param);
  }
  //获取源头
  public getChannelList(param: Dict<any>): Promise<any> {
    return this.http.get('/api/application/productmap/getChannelList');
  }
  //可见范围全部数据
  public getSelectGroupList(param: Dict<any>): Promise<any> {
    return this.http.post('/api/application/productmap/selectGroupList',param);
  }

  //添加图文分类
  public articleTypeAdd(param: Dict<any>): Promise<any> {
    return this.http.post('/api/application/productmap/save', param);
  }

 //删除图文分类
  public articleTypeDelete(id: string): Promise<any> {
    return this.http.post(`/api/application/articleType/delete/${id}`);
  }

  //修改图文分类
  public articleTypeUpdate(param: Dict<any>): Promise<any> {
    return this.http.post('/api/application/articleType/update', param);
  }

  //查询图文分类
  public articleTypeQuery(param: Dict<any>): Promise<any> {
    return this.http.get('/api/application/articleType/query', param);
  }

  // 获取指定类型的图文
  public getArticleType(typeId: Dict<any>,param: Dict<any>): Promise<any> {
      let config: any = {
        headers: { 'Content-Type': 'application/json' }
      };
      let queryString: any = {};
      return this.http.get(
        `/api/application/articleType/articles/${typeId}`,
        param,
        config
      );
  }

  //获取图文
  public articleQuery(param: Dict<any>): Promise<any> {
    return this.http.post('/api/application/article/query', param);
  }
  //查询指定图文
  public articleGet(param: Dict<any>): Promise<any> {
    return this.http.get(`/api/application/productmap/getById/`,param);
  }

  //删除图文
  public articleDelete(id: string): Promise<any> {
    return this.http.get(`/api/application/productmap/deleteProductMap/${id}`);
  }

  //批量修改图文
  // public articleUpdate(param: Dict<any>): Promise<any> {
  //   return this.http.post('/api/application/article/updates', param);
  // }
  //单个图文修改
  public articleUpdateOne(param: Dict<any>): Promise<any> {
    return this.http.post('/api/application/article/update', param);
  }
  //添加图文
  public articleAdd(param: Dict<any>): Promise<any> {
    return this.http.post('/api/application/productmap/save', param);
  }
  //更新图文
  public articleUpdate(param: Dict<any>): Promise<any> {
    return this.http.post('/api/application/productmap/update', param);
  }
    //预览图文
  public articlePreview(param: Dict<any>,type:string): Promise<any> {
      return this.http.post(`/api/application/productmap/lookContent/${type}`, param);
    }

  // 根据id获取图文详情 不包括子图文
  public articleDetail(id: string): Promise<any> {
    return this.http.get(`/api/application/article/info/${id}`);
  }

}
